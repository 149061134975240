.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-bottom: 1px solid #c3c3c3;
}

.wrapper :global .DateRangePicker_picker {
  z-index: 999;
}

.inner {
  display: flex;
  align-items: center;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.searchTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.wrapper :global .DateRangePickerInput {
  height: 100%;
  border: 0;
  border-radius: 28px 0 0 28px;
  overflow: hidden;
}

.wrapper :global .DateRangePicker {
  height: 100%;
}

.wrapper :global .DateRangePicker > div {
  height: 100%;
}

.wrapper :global .DateInput_input {
  height: 54px;
  padding: 11px 24px 9px;
}

.wrapper :global .DateInput_input__focused {
  border-color: transparent;
}

.rangePicker {
  margin-bottom: 0;
  height: 100%;
}

.wrapper .rangePicker__error :global .DateInput_input::placeholder {
  color: #ff4d4f;
}

.occupancyDropDown {
  margin-bottom: 0;
}

.occupancyDropDown :global .dropdown {
  height: 100%;
}

.occupancyDropDown :global .dropdown-toggle {
  height: 100%;
  border: none;
  border-radius: 0;
  padding-right: 16px;
}

.occupancyDropDown :global .btn-primary:not(:disabled):not(.disabled):focus {
  border: none;
}

.occupancyDropDown :global .btn-primary.dropdown-toggle {
  width: 195px;
}

.occupancyDropDown :global .show > .btn-primary.dropdown-toggle {
  border: none;
}

.occupancyDropDown :global .dropdown-menu.show {
  width: 305px;
  max-height: 535px;
  overflow: auto;
  z-index: 999;
}

.searchBtn {
  background: #00be0a;
  border-radius: 0 28px 28px 0;
  font-family: Helvetica;
  font-size: 16px;
  color: #ffffff;
  padding: 27px 8px;
  transition: all 200ms ease;
}

.searchBtn:global.btn-primary:hover,
.searchBtn:global.btn-primary:focus,
.searchBtn:global.btn-primary:active {
  transition: all 200ms ease;
  background: rgb(0 190 10 / 90%);
}

.searchBtn:global.btn-primary:not(:disabled):not(.disabled):active {
  background: rgb(0 190 10 / 90%);
}

.selectSection {
  display: flex;
  height: 100%;
}

@media (max-width: 1010px) {
  .wrapper {
    flex-direction: column;
    padding-top: 10px;
  }
}
@media (max-width: 840px) {
  .wrapper {
    flex-direction: column;
    padding-top: 10px;
  }
  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "LDate Date Date"
        "AdultChild Currency Language";
    align-items: center;
    justify-items: center;
    overflow-x: auto;
  }
  .searchTitle {
   font-size: 14px;
   grid-area: LDate; 
  }
  .rangePicker {
   grid-area: Date;
  }
}
@media (max-width: 440px) {
  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "LDate Date Date"
        "AdultChild Currency Language";
    align-items: center;
    justify-items: center;
    overflow-x: auto;
  }
  .searchTitle {
   grid-area: LDate;
   margin-left: -36px;
  }
  .rangePicker {
   grid-area: Date; 
   margin-left: -65px;
  }
  .occupancyDropDown{
    margin-left: 15px;
    margin-right: -26px;
  }
}
