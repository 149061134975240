.dropdownMenu {
  z-index: 2;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

.dropdownToggle {
  display: flex;
  width: 100%;
  height: 48px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--input-border-color);
  border-radius: 2px;
  transition: all 200ms ease;
  background-color: white;
  color: var(--main-text-color);
  font-size: 1rem; 
}

.dropdownToggle:hover,
.dropdownToggle:focus {
  background-color: white;
  color: var(--main-text-color);
  outline: none;
  box-shadow: none;
}

.dropdown:global>.btn-primary.dropdown-toggle,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):active {
  background-color: white;
  color: var(--main-text-color);
  box-shadow: none;
  outline: none;
  border-color: #dbdbdb;
}

.dropdown:global.show>.btn-primary.dropdown-toggle,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):focus {
  border-bottom: 2px solid #008489;
  margin-bottom: -1px;
}


.containerHorizontal,
.containerVertical {
  display: flex;
  margin-bottom: 8px;
}

.containerVertical {
  flex-direction: column;
}

.label {
  padding-right: 0.5rem;
  color: var(--info-text-color);
}

.dropdown, .dropdownToggle {
  width: 100%;
}

.guests-picker:hover {
  border-style: solid;
  border-color: #00aff5;
}

@media screen and (max-width: 575px) {
  .dropdownMenu {
    max-width: 100%;
  }
}