.wrapper {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 100%;
  max-width: 690px;
  /* height: 40vh; */
  min-height: 200px;
  position: relative;
}

@media (max-width: 1309px) {
  .right {
    max-width: 511px;
  }
}

@media (max-width: 930px) {
  .wrapper {
    flex-direction: column-reverse;
  }

  .right {
    max-width: 295px;
    /* height: 465px; */
    height: 400px;
    position: initial;
    max-width: 100%
  }
}

@media (max-width: 681px) {
  .right {
    height: 465px;
  }
}

@media (min-width: 930px) {
  .right {
    overflow: auto
  }
}
