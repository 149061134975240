.wrapper {
  overflow: auto;
  max-height: 100vh;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: #fff;
}

.imageWrapper {
  height: 400px;
  position: relative;
}

.image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.emptyImage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.closeWrapper {
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #007bff;
  border-color: #007bff;
  padding: 6px 14px;
  border-radius: 0.25rem;
  align-items: center;
}

.closeWrapper:hover {
  background-color: #0069d9;
  cursor: pointer;
}

.closeIcon {
  transform: rotate(180deg);
}

.closeLabel {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  margin: 0 0 0 5px;
  font-weight: 400;
}

.header {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f7f9fc;
  border-bottom: 1px solid #f7f9fc;
  box-sizing: border-box;
}

.headerInner {
  padding: 25px 30px;
}

.title {
  font-family: Helvetica;
  font-size: 28px;
  color: #232331;
  margin-bottom: 10px;
  line-height: 1.3;
}

.address {
  font-family: Helvetica;
  font-size: 12px;
  color: #939ea8;
  margin: 0;
  line-height: 1.5;
}

.seeMoreBtnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  border-left: 1px solid #f7f9fc;
}

.seeMoreBtnWrapper :global .btn {
  padding: 7px 23px;
  margin-top: 8px;
}

.seeMoreBtnWrapper :global .btn:focus,
.seeMoreBtnWrapper :global .btn:active,
.seeMoreBtnWrapper :global .btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.description {
  font-family: Helvetica;
  font-size: 14px;
  color: #424242;
  text-align: left;
  line-height: 24px;
  padding: 25px 30px;
}
@media (max-width: 840px) {
  .header {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f7f9fc;
    border-bottom: 1px solid #f7f9fc;
    box-sizing: border-box;
    flex-direction: column;
  }
}